import React from 'react';
import { navigate } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCat } from '@fortawesome/free-solid-svg-icons';

import SEO from '../components/content/seo';
import Layout from '../containers/layout';
import { Page, Section } from '../components/layout/page';
import Headline from '../components/layout/headline';

const seo = {
  title: 'Success',
  description: 'Success page',
  keywords: ['Success'],
};

const SuccessPage = ({ location }) => {
  const name = location.state && location.state.name ? location.state.name : '';
  const firstName = name.substring(0, name.indexOf(' ')); // Get first name
  const emphasis = firstName ? `Thank you, ${firstName}!` : `Thank you!`;

  // if (!location.state) {
  //   navigate('/404');
  // }

  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        meta={[
          {
            name: 'robots',
            content: 'noindex, follow',
          },
        ]}
      />
      {location.state && (
        <Page>
          <Section color='blue'>
            <p>
              <FontAwesomeIcon icon={faCat} size='10x' color='white' />
            </p>
            <Headline
              background='blue'
              lead='Request Received'
              emphasis={emphasis}
            />
            <h3>
              We appreciate your time in contacting us. We look forward to
              connecting soon.
            </h3>
          </Section>
          }
        </Page>
      )}
    </Layout>
  );
};

export default SuccessPage;
